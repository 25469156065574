import { Slot } from "@radix-ui/react-slot";
import { cn } from "@ui/utils";
import type { VariantProps } from "class-variance-authority";
import * as React from "react";

import { LoaderSpinner } from "../Loader";
import { buttonVariants } from "./style";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  loadingMessage?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, loading = false, loadingMessage, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }), className)} ref={ref} {...props}>
        {loading ? (
          <div className="inline-flex items-center">
            <LoaderSpinner />
            {loadingMessage && <p className="ml-2">{loadingMessage}</p>}
          </div>
        ) : (
          props.children
        )}
      </Comp>
    );
  },
);
Button.displayName = "Button";

export { Button };
