"use client";

import type { VariantProps } from "class-variance-authority";
import * as React from "react";
import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import { toggleVariants } from "@ui/components/ui/Toggle/style";

import type { InputWrapperProps } from "../Input/InputWrapper";
import { cn } from "../../../utils/classnames";
import { InputWrapper } from "../Input/InputWrapper";

const ToggleGroupContext = React.createContext<VariantProps<typeof toggleVariants>>({
  size: "default",
  variant: "default",
});

const BaseToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> & VariantProps<typeof toggleVariants>
>(({ className, variant, size, children, ...props }, ref) => (
  <ToggleGroupPrimitive.Root ref={ref} className={cn("flex items-center justify-center", className)} {...props}>
    <ToggleGroupContext.Provider value={{ variant, size }}>{children}</ToggleGroupContext.Provider>
  </ToggleGroupPrimitive.Root>
));

BaseToggleGroup.displayName = ToggleGroupPrimitive.Root.displayName;

const ToggleGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> & VariantProps<typeof toggleVariants>
>(({ className, children, variant, size, ...props }, ref) => {
  const context = React.useContext(ToggleGroupContext);

  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        toggleVariants({
          variant: context.variant ?? variant,
          size: context.size ?? size,
        }),
        className,
      )}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  );
});

const ToggleGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
    InputWrapperProps &
    VariantProps<typeof toggleVariants>
>(
  (
    {
      label,
      id,
      error,
      warning,
      required,
      showEmptyDivs = false,
      wrapperClassName,
      tooltip,
      reverseToolTip,
      tooltipAsError,
      ...rest
    },
    ref,
  ) => {
    const variant = error ? "error" : warning ? "warning" : "default";
    return (
      <InputWrapper
        id={id}
        required={required}
        wrapperClassName={wrapperClassName}
        label={label}
        warning={warning}
        error={error}
        showEmptyDivs={showEmptyDivs}
        tooltip={tooltip}
        variant={variant}
        reverseToolTip={reverseToolTip}
        tooltipAsError={tooltipAsError}
      >
        <BaseToggleGroup ref={ref} id={id} {...rest} />
      </InputWrapper>
    );
  },
);

ToggleGroupItem.displayName = ToggleGroupPrimitive.Item.displayName;

export { BaseToggleGroup, ToggleGroup, ToggleGroupItem };
