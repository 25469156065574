import { cn } from "@ui/utils";
import type { VariantProps } from "class-variance-authority";
import * as React from "react";

import { inputVariants } from "./style";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  onWheelActive?: (e: WheelEvent) => void;
  clearable?: () => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      endAdornment,
      startAdornment,
      variant,
      clearable,
      onWheelActive, // This event is added bu doesn't work on input (Causes error), so destructed out
      ...props
    },
    ref,
  ) => {
    return (
      <div className={cn(inputVariants({ variant }), className)}>
        {startAdornment && <div className="flex items-center border-e px-3">{startAdornment}</div>}
        <input
          className="w-full rounded-md px-3 py-2 file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50"
          type={type}
          ref={ref}
          {...props}
        />
        {endAdornment && <div className="flex items-center border-s px-3">{endAdornment}</div>}
        {clearable && (
          <button type="button" className="px-2" onClick={clearable}>
            <i className="ri-close-line" />
          </button>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
